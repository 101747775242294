













import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({ name: "PasswordTextbox", inheritAttrs: false })
export default class PasswordTextbox extends Vue {
    passwordToggle: boolean = true;

    @Prop({ required: true })
    value: string | null;

    @Prop({ required: false, type: String })
    name: string | null;

    get internalValue() {
        return this.value;
    }

    set internalValue(val: string | null) {
        this.$emit("input", val);
    }
}
