export default class DefaultValidationMessages {
    static readonly fieldPlaceholder: string = "{Field}";

    static readonly required: string = `${DefaultValidationMessages.fieldPlaceholder}  is required.`;
    static readonly email: string = `${DefaultValidationMessages.fieldPlaceholder}  must be a valid email address.`;
    static readonly url: string = `${DefaultValidationMessages.fieldPlaceholder}  must be a valid url.`;

    static minMaxLength(minLength: string | number | null, maxLength: string | number | null): string {
        if (minLength && maxLength) {
            return `${DefaultValidationMessages.fieldPlaceholder} must be between ${minLength} and ${maxLength} characters.`;
        }

        if (minLength) {
            return `${DefaultValidationMessages.fieldPlaceholder} must be at least ${minLength} characters.`;
        }

        if (maxLength) {
            return `${DefaultValidationMessages.fieldPlaceholder} must be no more than ${maxLength} characters.`;
        }

        return "";
    }

    static range(min: string | null, max: string | null): string {
        if (min && max) {
            return `${DefaultValidationMessages.fieldPlaceholder} must be between ${min} and ${max}.`;
        }

        if (min) {
            return `${DefaultValidationMessages.fieldPlaceholder} must be at least ${min}.`;
        }

        if (max) {
            return `${DefaultValidationMessages.fieldPlaceholder} must be no more than ${max}.`;
        }

        return "";
    }

    static pattern(pattern: string): string {
        return `${DefaultValidationMessages.fieldPlaceholder} is not in a valid format.`;
    }
}
