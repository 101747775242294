import { ActionContext, ActionTree } from "vuex";

import { restClient } from "../ajax";
import { ISelectListItem, ISettingsModel, IUserInfo } from "../models";
import { IClient } from "../models/clients";
import { ITimeChargeModel, TimeChargeModel } from "../models/time";
import IAppState from "./IAppState";
import { AppMutationTypes } from "./mutations";

export type GetSettingsAction = () => Promise<ISettingsModel>;
export type GetUserAction = () => Promise<IUserInfo>;
export type GetTimeEntryAction = (id: number) => Promise<ITimeChargeModel>;
export type GetClientsAction = () => Promise<IClient[]>;
export type GetClientItemsAction = () => Promise<ISelectListItem[]>;
export type GetProjectItemsAction = () => Promise<
    Record<number, ISelectListItem[]>
>;
export type GetUserItemsAction = () => Promise<ISelectListItem[]>;

export const AppActionTypes = {
    getSettings: "getSettings",
    getTimeEntry: "getTimeEntry",
    getClients: "getClients",
    getClientItems: "getClientItems",
    getProjectItems: "getProjectItems",
    getUserItems: "getUserItems"
};

const actions: ActionTree<IAppState, IAppState> = {
    async [AppActionTypes.getSettings](
        context: ActionContext<IAppState, IAppState>
    ): Promise<ISettingsModel> {
        if (
            context.state.settings &&
            context.state.settings.signInUrl &&
            context.state.settings.signInUrl.length > 0
        ) {
            return context.state.settings;
        }

        const response = await restClient.getJson<ISettingsModel>(
            "/api/Setting"
        );

        if (response.data) {
            context.commit(AppMutationTypes.setSettings, response.data);

            if (response.data.user && response.data.user.id > 0) {
                context.commit(AppMutationTypes.setUser, response.data.user);
            }

            return response.data;
        }

        throw Error("could not retrieve settings");
    },

    async [AppActionTypes.getTimeEntry](
        context: ActionContext<IAppState, IAppState>,
        payload: number
    ): Promise<ITimeChargeModel> {
        const timeEntry = context.state.timeEntry;
        if (timeEntry && timeEntry.id === payload) {
            return timeEntry;
        }

        const response = await restClient.getJson<ITimeChargeModel>(
            `/api/TimeCharge/${payload}`
        );
        if (response.data) {
            context.commit(AppMutationTypes.setTimeEntry, response.data);
            return response.data;
        }

        return new TimeChargeModel();
    },

    async [AppActionTypes.getClients](
        context: ActionContext<IAppState, IAppState>
    ): Promise<IClient[]> {
        if (context.state.clients && context.state.clients.length > 0) {
            return context.state.clients;
        }

        const response = await restClient.getJson<IClient[]>("/api/Client");
        context.commit(AppMutationTypes.setClients, response.data);

        return response.data || [];
    },

    async [AppActionTypes.getClientItems](
        context: ActionContext<IAppState, IAppState>
    ): Promise<ISelectListItem[]> {
        if (context.state.clientItems && context.state.clientItems.length > 0) {
            return context.state.clientItems;
        }

        const response = await restClient.getJson<ISelectListItem[]>(
            "/api/Client/Items"
        );

        if (response.status !== 200 || !response.data) {
            throw new Error("Could not load client dropdown items.");
        }

        context.commit(AppMutationTypes.setClientItems, response.data);

        return response.data;
    },

    async [AppActionTypes.getProjectItems](
        context: ActionContext<IAppState, IAppState>
    ): Promise<Record<number, ISelectListItem[]>> {
        if (
            context.state.projectItems &&
            Object.keys(context.state.projectItems).length > 0
        ) {
            return context.state.projectItems;
        }

        const response = await restClient.getJson<
            Record<number, ISelectListItem[]>
        >("/api/Project/Items");

        if (response.status !== 200 || !response.data) {
            throw new Error("Could not load project dropdown items.");
        }

        context.commit(AppMutationTypes.setProjectItems, response.data);

        return response.data;
    },

    async [AppActionTypes.getUserItems](
        context: ActionContext<IAppState, IAppState>
    ): Promise<ISelectListItem[]> {
        if (context.state.userItems && context.state.userItems.length > 0) {
            return context.state.userItems;
        }

        const response = await restClient.getJson<ISelectListItem[]>(
            "/api/User/Items"
        );

        if (response.status !== 200 || !response.data) {
            throw new Error("Could not load user items.");
        }

        context.commit(AppMutationTypes.setUserItems, response.data);

        return response.data;
    }
};

export default actions;
