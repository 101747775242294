import BootstrapVue from "bootstrap-vue";
import moment from "moment";
import Vue from "vue";
import Multiselect from "vue-multiselect";
import VueRouter from "vue-router";
import Datepicker from "vuejs-datepicker";

import Editor from "@tinymce/tinymce-vue";

import ActiveDeletedDropdown from "../components/ActiveDeletedDropdown.vue";
import Autocomplete from "../components/Autocomplete.vue";
import ClientDropdown from "../components/ClientDropdown.vue";
import ClientProjectEnvironmentDropdown from "../components/ClientProjectEnvironmentDropdown.vue";
import Editable from "../components/Editable.vue";
import EttDatePicker from "../components/EttDatePicker.vue";
import HtmlEditor from "../components/HtmlEditor.vue";
import PasswordTextbox from "../components/PasswordTextbox.vue";
import ProjectApplicationDropdown from "../components/ProjectApplicationDropdown.vue";
import ProjectDropdown from "../components/ProjectDropdown.vue";
import ProjectManagementTypeDropdown from "../components/ProjectManagementTypeDropdown.vue";
import StateDropdown from "../components/StateDropdown.vue";
import BaseTable from "../components/table/BaseTable";
import TaskAutocomplete from "../components/TaskAutocomplete.vue";
import TaskDropdown from "../components/TaskDropdown.vue";
import TaskStatusDropdown from "../components/TaskStatusDropdown.vue";
import UserDropdown from "../components/UserDropdown.vue";
import ValFormGroup from "../components/validation/ValFormGroup.vue";
import ValInput from "../components/validation/ValInput";
import ValSummary from "../components/validation/ValSummary.vue";
import CodeDirective from "../directives/CodeDirective";
import LoadingDirective from "../directives/LoadingDirective";
import { errorLog } from "../logging";
import configureAxios from "./configureAxios";

export const GLOBAL_ERROR_EVENT = "global-error";

export default function configure() {
    Vue.config.productionTip = false;

    // https://raygun.com/documentation/language-guides/javascript/vuejs/#step-3-setup-a-custom-error-handler
    Vue.config.errorHandler = function (err, vm, info) {
        errorLog.send(err, vm, info);
        // eslint-disable-next-line no-console
        console.error(err);
        // eslint-disable-next-line no-console
        console.error(info);
        // handles throwing the error
        vm.$root.$emit(GLOBAL_ERROR_EVENT, err);
    };

    // components
    addComponents();

    // directives
    addDirectives();

    // filters
    addFilters();

    configureAxios();
}

function addComponents() {
    Vue.use(VueRouter);
    Vue.use(BootstrapVue);

    Vue.component("multiselect", Multiselect);
    Vue.component("datepicker", Datepicker);
    Vue.component("ett-date-picker", EttDatePicker);

    Vue.component("editable", Editable);
    Vue.component("editor", Editor);
    Vue.component("html-editor", HtmlEditor);

    Vue.component("base-table", BaseTable);

    Vue.component("autocomplete", Autocomplete);
    Vue.component("user-dropdown", UserDropdown);
    Vue.component("project-dropdown", ProjectDropdown);
    Vue.component("task-dropdown", TaskDropdown);
    Vue.component("task-status-dropdown", TaskStatusDropdown);
    Vue.component("task-autocomplete", TaskAutocomplete);
    Vue.component("client-dropdown", ClientDropdown);
    Vue.component("state-dropdown", StateDropdown);
    Vue.component(
        "client-project-environment-dropdown",
        ClientProjectEnvironmentDropdown
    );
    Vue.component("project-application-dropdown", ProjectApplicationDropdown);
    Vue.component(
        "project-management-type-dropdown",
        ProjectManagementTypeDropdown
    );
    Vue.component("active-deleted-dropdown", ActiveDeletedDropdown);
    Vue.component("password-textbox", PasswordTextbox);

    Vue.component("val-form-group", ValFormGroup);
    Vue.component("val-input", ValInput);
    Vue.component("val-summary", ValSummary);
}

function addDirectives() {
    Vue.directive("loading", LoadingDirective);
    Vue.directive("code", CodeDirective);
}

function addFilters() {
    Vue.filter("decimal", (value: number) => {
        //value !== 0 was added because !value will return TRUE for 0 numbers.
        if (!value && value !== 0) {
            return "";
        }

        return Number(value).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        });
    });

    Vue.filter("date", (value: Date) => {
        if (!value) {
            return "";
        }
        return moment(value).format("MM/DD/YYYY");
    });

    Vue.filter("datetime", (value: Date) => {
        if (!value) {
            return "";
        }
        return moment(value).format("MM/DD/YYYY hh:mm A");
    });

    Vue.filter("striphtml", (value: string) => {
        const div = document.createElement("div");
        div.innerHTML = value;

        return div.textContent || div.innerText || "";
    });

    Vue.filter("truncate50", (value: string) => truncate(value, 50));
    Vue.filter("truncate150", (value: string) => truncate(value, 150));
}

function truncate(value: string, length: number): string {
    let text = value;

    if (text.length > length) {
        text = text.substring(0, length) + "...";
    }

    return text;
}
