import InputValidator from "./InputValidator";

export default class CustomValidator extends  InputValidator {
    constructor(element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement, message = "") {
        super(element, message);

        this.setMessage(message);
    }

    public setMessage(message: string) {
        this.message = message;
        this.element.setCustomValidity(message);
    }

    public validate(): boolean {
        this.valid = !this.element.validity.customError;

        this.emit();

        return this.valid;
    }
}
