





















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import CookieHelper from "../cookies/CookieHelper";

@Component({ name: "LayoutNavigation" })
export default class LayoutNavigation extends Vue {
    @Prop({ required: true })
    isAuthenticated: boolean;

    @Prop({ required: true })
    fullName: string;

    @Prop()
    photoUrl: string;

    private sidebarClass: string = "mini-sidebar";
    private cookieKey: string = "ett-navigation-collapsed";

    isBodyCollapsed(): boolean {
        return document.body.classList.contains(this.sidebarClass);
    }

    collapseBody() {
        if (!this.isBodyCollapsed()) {
            document.body.classList.add(this.sidebarClass);
        }
    }

    showBody() {
        document.body.classList.remove(this.sidebarClass);
    }

    getClientWidth() {
        return document.documentElement.clientWidth as number;
    }

    onResize() {
        const pageWidth = this.getClientWidth();
        if (pageWidth < 1024) {
            this.collapseBody();
        }
    }

    mounted() {
        //Add body class based on page width
        //(ex. mobile devices shouldn't load with the full navigation open, browsers should)
        const pageWidth = document.documentElement.clientWidth as number;
        if (pageWidth < 768) {
            //768 is bootstrap's 'md' size breakpoint

            //Per Bosko:
            //should automatically work that if the screen gets down to a certain size,
            //it automatically collapses the left nav, and the left nav stays collapsed even after making a selection in it
            //Should not stay open and overlap content

            this.collapseBody();
        } else {
            //If not a mobile device - use a cookie to remain consistent between pages:

            const cookieValue = CookieHelper.get(this.cookieKey);

            if (cookieValue === "true") {
                this.collapseBody();
            } else if (cookieValue === "false") {
                this.showBody();
            }
        }

        CookieHelper.set(this.cookieKey, this.isBodyCollapsed().toString());

        this.$root.$on("navigation-collapse", () => {
            if (this.isBodyCollapsed()) {
                this.showBody();
            } else {
                this.collapseBody();
            }
            CookieHelper.set(this.cookieKey, this.isBodyCollapsed().toString());
        });

        window.addEventListener("resize", this.onResize);
    }
}
