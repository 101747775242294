import Vue from "vue";
import { Component } from "vue-property-decorator";

import ValidatedEvent from "../../validation/ValidatedEvent";

@Component({ name: "ValInput" })
export default class ValInput extends Vue {
    state: boolean | null = null;

    get elementId(): string {
        if (!this.$el) {
            return "";
        }

        return this.$el.id;
    }

    mounted() {
        ValidatedEvent.attach(this.onValidated);
    }

    onValidated(e: ValidatedEvent) {
        if (this.elementId === e.detail.elementId) {
            this.state = e.detail.valid;
        }
    }

    destroyed() {
        ValidatedEvent.remove(this.onValidated);
    }

    render() {
        if (this.$scopedSlots && this.$scopedSlots.default) {
            return this.$scopedSlots.default({ state: this.state });
        }

        return "";
    }
}
