



import { Component } from "vue-property-decorator";
import { AppGetterTypes } from "../store";
import BaseDropdown from "./BaseDropdown";
import VuexDropdown from "./VuexDropdown.vue";

@Component({ name: "ClientProjectEnvironmentDropdown", components: { "vuex-dropdown": VuexDropdown } })
export default class ClientProjectEnvironmentDropdown extends BaseDropdown<number | null> {
    getter = AppGetterTypes.clientProjectEnvironmentItems;
}
