export default class CookieHelper {
    // we can't mock document.cookie, so this is set along with document.cookie so we can test
    static value: string;

    static get(key: string): string | null {
        const obj = CookieHelper.object;
        if (!obj.hasOwnProperty(key)) {
            return null;
        }

        return obj[key];
    }

    static get object(): { [key: string]: string } {
        const obj: { [key: string]: string } = {};

        const decodedCookie = decodeURIComponent(document.cookie);

        const cookiePairs = decodedCookie.split(";");

        cookiePairs.forEach((p) => {
            const eqi = p.indexOf("=");
            const pairArray = [
                p.substring(0, eqi).trim(),
                p.substring(eqi + 1).trim()
            ];

            obj[pairArray[0]] = pairArray[1];
        });

        return obj;
    }

    static set(
        key: string,
        value: string,
        expirationDate?: Date,
        path?: string
    ): void {
        if (expirationDate == null) {
            // default to one year from now
            expirationDate = new Date();
            expirationDate.setFullYear(expirationDate.getFullYear() + 1);
        }

        const expires = expirationDate.toUTCString();

        path = path || "/";

        const values = [
            `${key}=${value}`,
            `expires=${expires}`,
            `path=${path}`
        ];

        const cookie = values.join(";");

        document.cookie = cookie;
        CookieHelper.value = cookie;
    }

    static setJson(
        key: string,
        value: object,
        expirationDate?: Date,
        path?: string
    ): void {
        CookieHelper.set(
            key,
            JSON.stringify(value || {}),
            expirationDate,
            path
        );
    }

    static getJson<T>(key: string): T | null {
        const json = CookieHelper.get(key);
        if (json) {
            return JSON.parse(json) as T;
        }

        return null;
    }
}
