import VueRouter, { Route, RouteConfig } from "vue-router";

import { IUserInfo } from "../models";
import store from "../store";
import { AppMutationTypes } from "../store/mutations";
import {
    addClientRoute,
    addProjectsRoute,
    adminRoute,
    clientsRoute,
    editClientsRoute,
    editProjectsRoute,
    usersRoute
} from "./admin";
import getRouteConfig from "./getRouteConfig";
import homeRoute from "./homeRoute";
import IRouteNames from "./IRouteNames";
import redirectIfUnauthorized from "./redirectIfUnauthorized";

const loginRoute = getRouteConfig("Login", "/login", "Login", []);
const tasksRoute = getRouteConfig("tasks/Tasks", "/tasks", "Tasks", [
    homeRoute
]);
const editTasksRoute: RouteConfig = {
    ...getRouteConfig("tasks/EditTask", "/tasks/:id", "Edit Task", [
        homeRoute,
        tasksRoute
    ]),
    props: (route: Route) => ({
        id: Number(route.params.id),
        tab: route.query.tab ? Number(route.query.tab) : undefined
    })
};
const addTaskRoute = {
    ...getRouteConfig("tasks/EditTask", "/tasks/create", "Add Task", [
        homeRoute,
        tasksRoute
    ]),
    props: { isNew: true }
};
const timeEntriesRoute = {
    ...getRouteConfig("time/TimeEntries", "/time/entries", "Time Entry", [
        homeRoute
    ]),
    props: (route: Route) => ({
        task: route.query.task ? Number(route.query.task) : undefined,
        time: route.query.time ? Number(route.query.time) : undefined
    })
};
const reportsRoute = getRouteConfig("reports/Reports", "/reports", "Reports", [
    homeRoute
]);
const summaryReportRoute = getRouteConfig(
    "reports/SummaryReport",
    "/reports/summary",
    "Summary Report",
    [homeRoute, reportsRoute]
);
const fullReportRoute = getRouteConfig(
    "reports/FullReport",
    "/reports/full",
    "Full Report",
    [homeRoute, reportsRoute]
);
const projectsRoute = getRouteConfig("Projects", "/projects", "Projects", [
    homeRoute
]);
const credentialsRoute = getRouteConfig(
    "Credentials",
    "/credentials",
    "Credentials",
    [homeRoute]
);

export const routePaths: IRouteNames = {
    home: homeRoute.path,
    login: loginRoute.path,
    tasks: tasksRoute.path,
    editTask: editTasksRoute.path,
    addTask: addTaskRoute.path,
    time: timeEntriesRoute.path,
    projects: projectsRoute.path,
    credentials: credentialsRoute.path,
    reports: {
        home: reportsRoute.path,
        summary: summaryReportRoute.path,
        full: fullReportRoute.path
    },
    admin: {
        home: adminRoute.path,
        users: usersRoute.path,
        clients: clientsRoute.path,
        addClient: addClientRoute.path,
        editClient: editClientsRoute.path,
        editProject: editProjectsRoute.path,
        addProject: addProjectsRoute.path
    }
};

// we're only exporting the route names so that we don't accidentally reference the component imports
export const routeNames: IRouteNames = {
    home: homeRoute.name || "",
    login: loginRoute.name || "",
    tasks: tasksRoute.name || "",
    editTask: editTasksRoute.name || "",
    addTask: addTaskRoute.name || "",
    time: timeEntriesRoute.name || "",
    projects: projectsRoute.name || "",
    credentials: credentialsRoute.name || "",
    reports: {
        home: reportsRoute.name || "",
        summary: summaryReportRoute.name || "",
        full: fullReportRoute.name || ""
    },
    admin: {
        home: adminRoute.name || "",
        users: usersRoute.name || "",
        clients: clientsRoute.name || "",
        addClient: addClientRoute.name || "",
        editClient: editClientsRoute.name || "",
        editProject: editProjectsRoute.name || "",
        addProject: addProjectsRoute.name || ""
    }
};

const routes = [
    homeRoute,
    loginRoute,
    tasksRoute,
    addTaskRoute,
    editTasksRoute,
    timeEntriesRoute,
    projectsRoute,
    reportsRoute,
    summaryReportRoute,
    fullReportRoute,
    credentialsRoute,
    adminRoute,
    usersRoute,
    clientsRoute,
    editClientsRoute,
    addProjectsRoute,
    editProjectsRoute
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    const user: IUserInfo = store.state.user;

    // if the to and from paths match then the query string is changing and we do not want to reload
    if (store.state.updateAvailable && to.path !== from.path) {
        // https://stackoverflow.com/questions/61153418/is-it-possible-to-get-full-url-including-origin-from-route-in-vuejs
        const absoluteURL = new URL(to.fullPath, window.location.origin).href;
        window.location.href = absoluteURL;
    }

    redirectIfUnauthorized(user, to, next);
});

router.afterEach((to: Route, from: Route) => {
    if (to.meta) {
        // set the page title after navigation
        if (to.meta.title && to.meta.title.length > 0) {
            store.commit(AppMutationTypes.setTitle, to.meta.title);
        }
    }
});

export { IRouteNames };

export default router;
