import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

export default class SignalRHubHelper {
    public static createConnection(
        url: string,
        logLevel: LogLevel = LogLevel.Warning
    ): HubConnection {
        const builder = new HubConnectionBuilder();

        return builder
            .configureLogging(logLevel)
            .withUrl(url)
            .withAutomaticReconnect()
            .build();
    }
}
