







import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({ inheritAttrs: false, name: "Editable" })
export default class Editable extends Vue {
    @Prop({ required: true, default: false, type: Boolean })
    value: boolean;

    @Prop({ default: false, type: Boolean })
    empty: boolean;

    doClick(evt: Event) {
        const selection = window.getSelection();
        const hasText = selection && selection.toString().length > 0;

        if (!hasText) {
            this.$emit("input", true);
        }
    }
}
