// see https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-color-scheme
const darkMode =
    window && window.matchMedia
        ? window.matchMedia("(prefers-color-scheme: dark)").matches
        : false;

export default class DefaultTinyMceConfig {
    // ReSharper disable InconsistentNaming
    // ReSharper disable IdentifierTypo
    plugins: string =
        "paste print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help autoresize";
    paste_data_images: boolean = true;
    powerpaste_word_import: string = "clean";
    powerpaste_html_import: string = "merge";
    browser_spellcheck: boolean = true;
    toolbar: string =
        "formatselect fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | codesample | removeformat";
    contextmenu: boolean = false;

    // see https://www.tiny.cloud/docs/plugins/codesample/#codesample_languages
    // languages should match custom script add /public/prism/prism.js
    codesample_languages = [
        { text: "ASP.NET (C#)", value: "aspnet" },
        { text: "C-like", value: "clike" },
        { text: "C#", value: "csharp" },
        { text: "CSS", value: "css" },
        { text: "HTML/XML", value: "markup" },
        { text: "JavaScript", value: "javascript" },
        { text: "JSON", value: "json" },
        { text: "LESS", value: "less" },
        { text: "Markdown", value: "md" },
        { text: "Sass", value: "scss" },
        { text: "SQL", value: "sql" },
        { text: "TypeScript", value: "typescript" },
        { text: "YAML", value: "yml" }
    ];

    height: number = 200;
    // the min/max height for the autoresize plugin - https://www.tiny.cloud/docs/plugins/autoresize/
    min_height: number = 200;
    max_height: number = 600;

    // see https://www.tiny.cloud/blog/dark-mode-tinymce-rich-text-editor/
    skin = darkMode ? "oxide-dark" : "oxide";
    content_css = darkMode ? "dark" : "default";
    // ReSharper restore InconsistentNaming
    // ReSharper restore IdentifierTypo
}
