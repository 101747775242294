/* eslint-disable @typescript-eslint/no-explicit-any */
import NetworkErrorEventModel from "./NetworkErrorEventModel";

/**
 * OBSOLETE - replace with RestClient
 *
 * @export
 * @deprecated replace with RestClient
 * @class FetchHelper
 */
export default class FetchHelper {
    static get(url: string): Promise<Response> {
        return FetchHelper.fetch(url, "GET");
    }

    static getJson(url: string): Promise<any> {
        return FetchHelper.fetchJson(url, "GET");
    }

    static post(url: string, body?: any): Promise<Response> {
        return FetchHelper.fetch(url, "POST", body);
    }

    static postJson(url: string, data?: object): Promise<any> {
        return FetchHelper.fetchJson(url, "POST", data);
    }

    static put(url: string, body?: any): Promise<Response> {
        return FetchHelper.fetch(url, "PUT", body);
    }

    static putJson(url: string, data?: object): Promise<any> {
        return FetchHelper.fetchJson(url, "PUT", data);
    }

    static delete(url: string, body?: any): Promise<Response> {
        return FetchHelper.fetch(url, "DELETE", body);
    }

    static fetch(url: string, method: string, body?: any): Promise<any> {
        return fetch(url, {
            method: method,
            body: body || "",
            credentials: "same-origin",
            redirect: "manual"
        }).then((response) => {
            this.checkForAppUpdate(response);
            return response;
        });
    }

    static fetchJson(url: string, method: string, body?: object): Promise<any> {
        const headers = {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest"
        };
        return fetch(url, {
            method: method,
            body: JSON.stringify(body),
            headers: headers,
            credentials: "same-origin"
        }).then((response) => {
            const responseJson = response.json();

            this.checkForAppUpdate(response);

            if (response.status === 200) {
                return responseJson;
            }

            const model: NetworkErrorEventModel = {
                message: "An error occurred while processing the request.",
                httpStatusCode: response.status
            };
            window.dispatchEvent(
                new CustomEvent(NetworkErrorEventModel.EVENT_NAME, {
                    detail: model
                })
            );

            return responseJson.then((jsonResponse) =>
                Promise.reject(jsonResponse)
            );
        });
    }

    static checkForAppUpdate(response: Response): void {
        const apiVersion = process.env.VUE_APP_VERSION;

        if (
            response?.headers &&
            response.headers.get("x-api-version") !== apiVersion
        ) {
            window.dispatchEvent(
                new CustomEvent("api-update", {
                    detail: true
                })
            );
        }
    }
}
