import moment from "moment";

import ITimeChargeModel from "./ITimeChargeModel";

export default class TimeChargeModel implements ITimeChargeModel {
    teamMemberName: string | null;
    pullRequestLink: string | null;
    clientId: number | null = null;
    projectId: number | null = null;
    taskId: number = 0;
    timeEntryType: number | null = null;
    userId: number | null = null;
    taskStatusId: number;
    client: string = "";
    project: string = "";
    date: Date = new Date();
    task: string = "";
    hours: number | null = null;
    timeStart = moment(new Date()).format("HH:mm");
    timeEnd = moment(new Date()).format("HH:mm");
    type: string = "";
    releaseNumber: string = "";
    id: number = 0;
    saveSuccessful: boolean = false;
    description: string = "";
    copyToTaskComment: boolean = true;
    timeChargeUserId: number | null = null;
    pullRequestNumber: number | null;
}
