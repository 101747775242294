












import { Component } from "vue-property-decorator";
import { AppGetterTypes } from "../store";
import BaseDropdown from "./BaseDropdown";
import VuexDropdown from "./VuexDropdown.vue";

@Component({ name: "StateDropdown", components: { "vuex-dropdown": VuexDropdown } })
export default class StateDropdown extends BaseDropdown {
    getter = AppGetterTypes.stateItems;
}
