import { BTable } from "bootstrap-vue";
import { assign, debounce, has, keys } from "lodash";
import { CreateElement, VNode, VNodeChildren, VNodeData } from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ScopedSlot } from "vue/types/vnode";

import { ColumnDefinitions } from "./ColumnTypes";
import { IProviderCtx } from "./IProviderCtx";
import { VueTable } from "./VueTable";

@Component({ name: "BaseTable" })
export default class BaseTable extends VueTable {
    $refs: {
        baseTable: BTable;
    };

    @Prop()
    tableId: string;

    @Prop({ default: null })
    filterModel: any;

    @Prop()
    tableClass: any;

    @Prop({ type: [String, Array] })
    tbodyClass: string | Array<string>;

    @Prop({ required: true, type: String })
    url: string;

    @Prop({ type: Promise })
    waitLoad: Promise<any>;

    @Prop({
        required: true,
        validator(columns: ColumnDefinitions) {
            if (!Array.isArray(columns)) {
                return false;
            }

            for (let i = 0; i < columns.length; i++) {
                const col = columns[i];
                if (typeof col !== "string" && !col.key) {
                    return false;
                }
            }
            return true;
        }
    })
    columns: ColumnDefinitions;

    @Prop({ default: true, type: [Boolean, Number] })
    debounce: boolean | number;

    @Prop({ type: Number })
    initPageNumber: number;

    @Prop({ type: Number })
    initPageSize: number;

    @Prop({ default: false, type: Boolean })
    disablePaging: boolean;

    /** If false, refresh is called each time the given filterModel changes. Defaults to true. */
    @Prop({ default: true })
    autoRefresh: boolean;

    loading: boolean = false;

    loadInitPaging() {
        if (this.disablePaging) {
            this.pageSize = 2147483647; // set page size to C# int.MaxValue if paging is disabled
            this.pageNumber = 1;
        }

        if (this.initPageNumber) {
            this.pageNumber = this.initPageNumber;
        }

        if (this.initPageSize) {
            this.pageSize = this.initPageSize;
        }
    }

    refresh() {
        if (this.$refs.baseTable) {
            this.$refs.baseTable.refresh();
        }
    }

    // executes a method inside of the busy state of the table, then refreshes it.
    // Allows us to change multiple properties (filter + sort) and only have 1 call to database
    busyExecute(fn: () => Promise<any> | void) {
        // used for changing multiple things that would reset the grid. Busy state prevents auto refresh
        this.loading = true;
        const table = this.$refs.baseTable;
        return table
            .$nextTick()
            .then(fn)
            .then(() => {
                this.loading = false;
                return table.$nextTick();
            })
            .then(() => {
                table.refresh();
            });
    }

    created() {
        this.loadInitPaging();
        this.$on("table-refresh", this.refresh);

        // Delay 1000ms for the request on filter model updates
        let refreshDebounce = this.refresh;
        let debounceTime = 1000;
        if (this.debounce) {
            if (typeof this.debounce === "number") {
                debounceTime = this.debounce;
            }

            refreshDebounce = debounce(this.refresh, debounceTime);
        }

        if (this.autoRefresh) {
            this.$watch(
                "filterModel",
                () => {
                    // Don't trigger debounce if it's in busy state
                    if (this.loading) {
                        return;
                    }
                    refreshDebounce();
                },
                { deep: true }
            );
        }
    }

    loadData(ctx: IProviderCtx) {
        return (this.waitLoad || Promise.resolve())
            .then(() => this.handleRequest(this.url, ctx, () => this.filterModel || {}))
            .then((items: any[]) => {
                if (items.length == 0 && this.pageNumber > 1) {
                    //This means the user got to a page that they weren't supposed to.
                    //Ex.
                    //  I'm on page 4 of Person A's list.
                    //  I switch to Person B's list.
                    //  Person B has only 3 pages of items, but I'm still on page 4.
                    this.pageNumber = 1;
                    ctx.currentPage = 1;
                    return this.handleRequest(this.url, ctx, () => this.filterModel || {});
                }

                return items;
            })
            .catch(response => {
                this.$emit("load-error", response);
                return [];
            });
    }

    private get columnKeys(): Array<string> {
        return this.columns.map(c => {
            if (typeof c === "string") {
                return c;
            } else {
                return c.key.toString();
            }
        });
    }

    private hasSlot(key: string): boolean {
        return has(this.$scopedSlots, key) || has(this.$slots, key);
    }

    private hasFooterSlot(): boolean {
        return (
            keys(this.$scopedSlots)
                .concat(keys(this.$slots))
                // eslint-disable-next-line no-useless-escape
                .some(k => !!k && !!k.match(/^foot\([^\)]+\)$/))
        );
    }

    private blankFooterSlots(create: CreateElement): { [key: string]: () => VNode } {
        const obj: { [key: string]: () => VNode } = {};
        this.columnKeys
            .filter(c => !this.hasSlot(`foot(${c})`))
            .forEach(c => {
                obj[`foot(${c})`] = () =>
                    create("span", {
                        domProps: {
                            innerHTML: "&nbsp;"
                        }
                    });
            });

        return obj;
    }

    render(create: CreateElement): VNode {
        const children: VNodeChildren = [];

        const slots: { [key: string]: ScopedSlot | undefined } = {};
        // transform non-scoped slots into scopedSlots to pass to b-table
        keys(this.$slots).forEach(k => {
            if (!has(slots, k)) {
                const s = () => this.$slots[k];
                slots[k] = s;
            }
        });
        keys(this.$scopedSlots).forEach(k => {
            if (!has(slots, k)) {
                const s = this.$scopedSlots[k];
                slots[k] = s;
            }
        });

        const hasFooter = this.hasFooterSlot();

        // If 1 footer is defined, automatically fill other footers with blank data (they otherwise automatically fill with header name)
        if (hasFooter) {
            assign(slots, this.blankFooterSlots(create));
        }

        // Create table element
        const tableData: VNodeData = {
            props: {
                items: this.loadData,
                sortBy: this.sortColumn,
                sortDesc: this.sortDescending,
                perPage: this.pageSize,
                currentPage: this.pageNumber,
                fields: this.columns,
                bordered: true,
                striped: true,
                busy: this.loading,
                responsive: "xxl",
                tbodyClass: this.tbodyClass,
                footClone: hasFooter,
                emptyText: "No records returned",
                showEmpty: true,
                id: this.tableId
            },
            ref: "baseTable",
            class: this.tableClass,
            on: {
                "update:sortBy": (column: string) => {
                    this.sortColumn = column;
                },
                "update:sortDesc": (descending: boolean) => {
                    this.sortDescending = descending;
                },
                "update:busy": (busy: boolean) => {
                    this.loading = busy;
                }
            },
            scopedSlots: slots
        };

        const table = create("b-table", tableData);

        children.push(table);

        if (this.loading) {
            const loadingElement = create(
                "div",
                {
                    class: "w-100 text-center"
                },
                [create("i", { class: "fa fa-spinner fa-spin fa-3x" })]
            );
            children.push(loadingElement);
        }

        if (!this.disablePaging) {
            // Create pagination element
            const pagination = create("b-pagination", {
                props: {
                    totalRows: this.totalItemCount,
                    value: this.pageNumber,
                    perPage: this.pageSize
                },
                class: "mb-0",
                on: {
                    input: (pageNum: number) => {
                        this.pageNumber = pageNum;
                    }
                }
            });

            // Create page size dropdown
            const pageSizeDropdown = create("b-form-select", {
                props: {
                    options: this.pageSizeOptions,
                    value: this.pageSize
                },
                on: {
                    input: (newSize: number) => {
                        this.pageSize = newSize;
                    }
                }
            });

            // Create page counts text
            const firstItemNumber = (this.pageNumber - 1) * this.pageSize + 1;
            const lastPageItemNumber = Math.min(firstItemNumber + this.pageSize - 1, this.totalItemCount);

            // Add paging elements to a row
            const pagingRow = create(
                "b-row",
                {
                    props: {
                        alignV: "center"
                    }
                },
                [
                    create("b-col", { props: { md: "auto" } }, [pagination]),
                    create("b-col", { props: { md: "auto" } }, [pageSizeDropdown]),
                    !this.loading
                        ? create(
                            "b-col",
                            { props: { md: "auto" } },
                            `${firstItemNumber} - ${lastPageItemNumber} of ${this.totalItemCount}`
                        )
                        : null
                ]
            );

            children.push(pagingRow);
        }

        // Add all created elements to a row
        return create("b-row", [create("b-col", children)]);
    }
}
