import { IValidationResult } from "../IValidationResult";
import { IValidator } from "../IValidator";
import ValidatedEvent from "../ValidatedEvent";

// The base input validation implementation
export default class InputValidator implements IValidationResult, IValidator {
    protected readonly element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    public message: string;
    public valid: boolean | null;
    public log: boolean;

    public get elementId(): string {
        return this.element.id;
    }

    protected constructor(
        element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement,
        message = "",
        log = false,
        validateOnBlur = true
    ) {
        this.element = element;
        this.message = message;
        this.log = log;

        // validate on change
        if (validateOnBlur) {
            this.element.addEventListener("blur", (e: Event) => {
                if (this.valid == null || this.valid) {
                    this.validate();
                }
            });
        }
    }

    // validates the input with the validity api - https://developer.mozilla.org/en-US/docs/Learn/HTML/Forms/Form_validation
    public validate(): boolean {
        this.valid = this.element.validity.valid;

        this.emit();

        return this.valid;
    }

    protected emit(): void {
        ValidatedEvent.emit(this);

        if (this.log) {
            // eslint-disable-next-line no-console
            console.log(
                `${this.element.id} ${this.constructor.name}: ${this.valid ? "Valid" : "Invalid"} (${
                    this.element.value
                }) - ${this.message}`
            );
        }
    }
}
