import { IValidationSummary } from "./IValidationSummary";

export default class ModelStateParser {
    public addValidationSummaryErrors(
        modelState: any,
        validationSummary: IValidationSummary | null = null,
        namePrefix = ""
    ) {
        for (const name in modelState) {
            const errors: string[] = modelState[name];

            for (let i = 0; i < errors.length; i++) {
                if (validationSummary != null) {
                    if (validationSummary.errors == null) {
                        validationSummary.errors = [];
                    }
                    validationSummary.errors.push(errors[i]);
                } else {
                    const errorMessages = errors.join(" ");
                    // eslint-disable-next-line no-console
                    console.error(`Missing validation summary. Errors: ${errorMessages}`);
                }
            }
        }
    }

    public addValidationErrorsPromise(
        modelState: any,
        validationSummary: IValidationSummary | null = null
    ): Promise<any> {
        this.addValidationSummaryErrors(modelState, validationSummary);
        return Promise.resolve(modelState);
    }
}
