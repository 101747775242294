import { VNode, DirectiveOptions } from "vue";
import { DirectiveBinding } from "vue/types/options";
import * as Prism from "prismjs";

const CodeDirective: DirectiveOptions = {
    bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
        if (el.querySelectorAll("pre code").length > 0) {
            Prism.highlightAllUnder(el, true);
        }
    }
};

export default CodeDirective;
