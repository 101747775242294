import InputValidator from "./InputValidator";

export default class LengthValidator extends InputValidator {
    constructor(element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement, message: string) {
        super(element, message);
    }

    public validate(): boolean {
        if (this.log) {
            // eslint-disable-next-line no-console
            console.log(
                `validity - tooLong: ${this.element.validity.tooLong}, tooShort: ${this.element.validity.tooShort}`
            );
        }

        // tooLong and tooShort aren't supported by all browsers, so we might need to calculate it manually
        let tooLong = this.element.validity.tooLong;
        if (this.element.value && (tooLong === undefined || !tooLong) && this.element.hasAttribute("maxlength")) {
            const maxLength = Number(this.element.getAttribute("maxlength"));
            tooLong = this.element.value.length > maxLength;
        }

        let tooShort = this.element.validity.tooShort;
        if (this.element.value && (tooShort === undefined || !tooShort) && this.element.hasAttribute("minlength")) {
            const minLength = Number(this.element.getAttribute("minlength"));
            tooShort = this.element.value.length < minLength;
        }

        if (this.log) {
            // eslint-disable-next-line no-console
            console.log(`calculated - tooLong: ${tooLong}, tooShort: ${tooShort}`);
        }

        this.valid = !(tooLong || tooShort);

        this.emit();

        return this.valid;
    }
}
