























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ISelectListItem } from "../models";

@Component({ name: "Autocomplete" })
export default class Autocomplete extends Vue {
    //Holds the v-model value (will update in real-time if requireSelection is false)
    @Prop()
    value: string;

    @Prop({ required: false, default: [] })
    items: ISelectListItem[];

    //When this is true the component will behave like a combobox
    //A combobox allows the users to type a value directly or select a value from the list below
    @Prop({ required: false, default: false })
    combobox: boolean;

    //True = v-model gets bound to the dropdown item the user selects (value of SelectListItem)
    //False = v-model gets bound to the textbox (value of textbox)
    //--
    //(If you are substituting a dropdown list for this, you might want this to be true)
    @Prop({ required: false, default: false, type: Boolean })
    requireSelection: boolean;

    //------------------------Loading properties------------------------------------
    @Prop({ required: false, type: Boolean, default: false })
    isLoading: boolean;

    @Prop({ required: false, type: String, default: "Loading..." })
    loadingText: string;
    //------------------------------------------------------------------------------

    @Prop({ required: false, type: Boolean, default: false })
    disabled: boolean;

    isOpen: boolean = false;

    //Holds the value for the active search
    search: string = "";

    mounted() {
        //Set search value to current value in v-model
        if (this.value) {
            if (!this.combobox){
                this.valueChange(this.value);
            }
            else {
                this.search = this.value;
            }
        }
    }

    //Watch for a v-model change, because we will need to update the textbox based on the select list item's value.
    @Watch("value")
    valueChange(value: string) {
        // eslint-disable-next-line eqeqeq
        const items = this.items.filter(item => item.value == this.value);

        if (!this.combobox) {
            if (items.length > 0) {
                this.search = items[0].text;
            } else {
                this.search = "";
            }
        }
    }

    @Watch("items")
    itemsChange(items: ISelectListItem[]) {
        this.valueChange(this.value);

        if (this.requireSelection && !this.matchingItem) {
            //If the items change and no matching item was found - reset the form.
            this.search = "";
            this.$emit("input", null);
        } else if (this.matchingItem) {
            this.$emit("input", this.combobox ? this.matchingItem.text : this.matchingItem.value);
        }

        if (this.items && this.items.length > 0 && !this.combobox) {
            this.search = this.matchingItem && this.matchingItem.text ? this.matchingItem.text : "";
        }
    }

    //Input event for the textbox.
    @Watch("search")
    searchChanged(val: string) {
        if (!this.requireSelection || this.matchingItem) {
            let value = this.search;

            if (this.matchingItem && !this.combobox) {
                value = this.matchingItem.value;
            }

            this.$emit("input", value);
        }
    }

    //Gets the dropdown items that match the current search.
    get activeItems(): ISelectListItem[] {
        if (!this.search || !this.search.toLowerCase) {
            return this.items;
        }

        return this.items.filter(item => item.text.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
    }

    //Gets the first dropdown item that is an exact match for the current search.
    get matchingItem(): ISelectListItem | null {
        if (!this.search || !this.search.toLowerCase) {
            return null;
        }

        const items = this.items.filter(item => item.text.toLowerCase() === this.search.toLowerCase());

        if (items && items.length > 0) {
            return items[0];
        }

        return null;
    }

    //Sets the value based on the dropdown item the user just selected.
    setResult(item: ISelectListItem) {
        this.$emit("input", this.combobox ? item.text : item.value);
        this.search = item.text;

        this.isOpen = false;
    }

    searchFocus() {
        this.isOpen = true;
    }

    searchBlur() {
        this.isOpen = false;
        this.valueChange(this.value);
    }
}
