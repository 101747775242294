import InputValidator from "./InputValidator";

export default class ServerSideValidator extends InputValidator {
    private _validated: boolean = false;

    constructor(element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement, message: string) {
        super(element, message, false, false);

        this.element.setCustomValidity(message);
        this.validate();
    }

    public validate(): boolean {
        // only validate once
        if (this._validated) {
            this.element.setCustomValidity("");
            this.message = "";
        }

        this._validated = true;

        // validity.customError isn't supported in all browsers, so check both that and the message length
        this.valid = !(this.element.validity.customError || this.message.length > 0);

        this.emit();

        return this.valid;
    }
}
