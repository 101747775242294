




























import Vue from "vue";
import Component from "vue-class-component";
import { AppMutationTypes } from "@/store/mutations";
import store from "@/store";
@Component({})
export default class UpdateAvailableNotification extends Vue {
    showUpdatePopup = false;

    mounted() {
        window.addEventListener("api-update", this.handleAppUpdate);
    }

    handleAppUpdate(e: Event): void {
        this.showUpdatePopup = true;
        store.commit(AppMutationTypes.setUpdateAvailable, true);
    }
    updateSite(): void {
        location.reload();
    }
    closePopup(): void {
        this.showUpdatePopup = false;
    }
}
