import InputValidator from "./InputValidator";

export default class RequiredValidator extends InputValidator {
    constructor(element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement, message: string) {
        super(element, message);
    }

    public validate(): boolean {
        this.valid = !this.element.validity.valueMissing;

        this.emit();

        return this.valid;
    }
}
