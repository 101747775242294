












































































































import Vue from "vue";
import Component from "vue-class-component";
import { Inject } from "vue-property-decorator";
import { IRouteNames } from "../router";

@Component
export default class SideMenu extends Vue {
    @Inject()
    routePaths: IRouteNames;

    @Inject()
    routeNames: IRouteNames;
}
