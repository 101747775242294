














import { Component, Prop } from "vue-property-decorator";
import { ISelectListItem } from "../models";
import AjaxDropdown from "./AjaxDropdown.vue";
import BaseDropdown from "./BaseDropdown";

@Component({ name: "TaskDropdown", components: { "ajax-dropdown": AjaxDropdown } })
export default class TaskDropdown extends BaseDropdown {
    @Prop({ default: false, type: Boolean })
    disableCache: boolean;

    @Prop({ required: true })
    projectId: number | null;

    @Prop({ required: false, default: false, type: Boolean })
    allowEligibleParentTasks: boolean;

    @Prop({ required: false, default: null, type: Number })
    excludeTaskId: number | null;

    get url(): string | null {
        if (!this.projectId) {
            return null;
        }

        const excludeTaskId: string =
            this.excludeTaskId == null ? "" : "&excludeTaskId=" + this.excludeTaskId.toString();

        return `/api/Task/Items/?projectId=${
            this.projectId
        }&eligibleParentTasks=${this.allowEligibleParentTasks.toString()}${excludeTaskId}`;
    }

    ddLoaded(options: ISelectListItem[]) {
        if (!options || !options.some(o => o.value == this.localValue)) {
            this.localValue = null;
        }
        this.$emit("loaded", options);
    }
}
