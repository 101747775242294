import { IValidationResult } from "./IValidationResult";

export default class ValidatedEvent extends CustomEvent<IValidationResult> {
    public static EventName: string = "input:validated";

    public static emit(result: IValidationResult) {
        window.dispatchEvent(new CustomEvent(ValidatedEvent.EventName, { detail: result }));
    }

    public static attach(handler: (event: ValidatedEvent) => void) {
        const eventHandler = handler as (event: Event) => void;
        window.addEventListener(ValidatedEvent.EventName, eventHandler);
    }

    public static remove(handler: (event: ValidatedEvent) => void) {
        const eventHandler = handler as (event: Event) => void;
        window.removeEventListener(ValidatedEvent.EventName, eventHandler);
    }
}
