import { Route, RouteConfig } from "vue-router";

import getRouteConfig from "./getRouteConfig";
import homeRoute from "./homeRoute";

const adminRole = "Admin";

function getAdminRouteConfig(view: string, path: string, title: string, breadcrumbs: RouteConfig[]): RouteConfig {
    return getRouteConfig(view, path, title, breadcrumbs, adminRole);
}

export const adminRoute = getAdminRouteConfig("admin/Admin", "/admin", "Admin", [homeRoute]);
export const usersRoute = getAdminRouteConfig("admin/users/Users", "/admin/users", "Users", [homeRoute, adminRoute]);
export const clientsRoute = getAdminRouteConfig("admin/clients/Clients", "/admin/clients", "Clients", [
    homeRoute,
    adminRoute
]);

export const editClientsRoute = {
    ...getAdminRouteConfig("admin/clients/EditClient", "/admin/clients/:id", "Edit Client", [
        homeRoute,
        adminRoute,
        clientsRoute
    ]),
    props: (route: Route) => ({
        clientId: Number(route.params.id),
        tabIndex: route.query.tab ? Number(route.query.tab) : 0,
        saveSuccessful: route.query.saveSuccessful === "true"
    })
};

export const addClientRoute = {
    ...getAdminRouteConfig("admin/clients/EditClient", "/admin/clients/create", "Add Client", [
        homeRoute,
        adminRoute,
        clientsRoute
    ])
};

export const addProjectsRoute = {
    ...getAdminRouteConfig("admin/projects/EditProject", "/admin/clients/:clientId/projects/create", "Add Project", [
        homeRoute,
        adminRoute,
        clientsRoute
    ]),
    props: (route: Route) => ({
        clientId: Number(route.params.clientId),
        projectId: 0
    })
};

export const editProjectsRoute = {
    ...getAdminRouteConfig(
        "admin/projects/EditProject",
        "/admin/clients/:clientId/projects/:projectId",
        "Edit Project",
        [homeRoute, adminRoute, clientsRoute, editClientsRoute]
    ),
    props: (route: Route) => ({
        clientId: Number(route.params.clientId),
        projectId: route.params.projectId ? Number(route.params.projectId) : 0
    })
};
