





















import BaseDropdown from "./BaseDropdown";
import { Component, Prop, Watch, Emit } from "vue-property-decorator";
import { ISelectListItem } from "../models";

@Component({ name: "VuexDropdown" })
export default class VuexDropdown extends BaseDropdown {
    /** Vuex action to call to retrieve select list items */
    @Prop({ required: false })
    vuexAction: string | string[];

    /** Vuex getter for retrieving select list items */
    @Prop({ required: true })
    vuexGetter: string | (() => ISelectListItem[]);

    @Emit("change")
    change() {
        return;
    }

    get options(): ISelectListItem[] {
        if (typeof this.vuexGetter === "string") {
            const options = this.$store.getters[this.vuexGetter];

            // if the array is empty, dispatch the action
            if (options.length === 0) {
                this.dispatchAction(this.vuexAction);
            }
            return options;
        }

        return this.vuexGetter();
    }

    @Watch("vuexAction", { immediate: true })
    async dispatchAction(newValue: string | string[]): Promise<void> {
        if (newValue) {
            if (Array.isArray(newValue)) {
                newValue.forEach((value) => this.$store.dispatch(value));
            } else {
                await this.$store.dispatch(newValue);
            }
        }
    }
}
