



import Vue from "vue";
import { Component } from "vue-property-decorator";
import moment from "moment";

@Component({ name: "DateLabel" })
export default class DateLabel extends Vue {
    get dateString(): string {
        return moment(new Date()).format("dddd M/D");
    }
}
