












import { Component, Prop } from "vue-property-decorator";
import AjaxDropdown from "./AjaxDropdown.vue";
import BaseDropdown from "./BaseDropdown";

@Component({ name: "ProjectApplicationDropdown", components: { "ajax-dropdown": AjaxDropdown } })
export default class ProjectApplicationDropdown extends BaseDropdown {
    @Prop({ required: true, type: Number })
    projectId: number;

    get url() {
        return `/api/Admin/ProjectApplication/Items?projectId=${this.projectId}`;
    }
}
