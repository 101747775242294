








import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ValidatedEvent, DefaultValidationMessages } from "../../validation";

@Component({ name: "ValFormGroup" })
export default class ValFormGroup extends Vue {
    private readonly invalidLabelClass: string = "text-danger";

    @Prop({ default: "" })
    label: string;

    labelClass: string | null = null;

    message: string = "";
    state: boolean | null = null;

    $refs: {
        label: HTMLLabelElement;
    };

    getElement(): HTMLElement | null {
        if (this.$el) {
            return this.$el.querySelector("input, select, textarea");
        }

        return null;
    }

    // Note: if elementId is watched or referenced in the template, it will get reset to "" - maybe a vue bug??
    get elementId(): string {
        const element = this.getElement();
        if (!element) {
            return "";
        }

        return element.id;
    }

    mounted() {
        ValidatedEvent.attach(this.onValidated);

        const element = this.getElement();

        if (element) {
            // validation doesn't happen until the form is submitted, so manually set the initial (server-side) message
            const initialError = element.dataset.initialMessage;
            if (initialError && initialError.length > 0) {
                this.state = false;
                this.message = element.dataset.initialMessage || "";

                ValidatedEvent.emit({
                    elementId: element.id,
                    valid: false,
                    message: initialError
                });
            }
        } else {
            throw new Error("no element found");
        }
    }

    onValidated(e: ValidatedEvent) {
        if (this.elementId === e.detail.elementId) {
            this.state = e.detail.valid;
            this.message = e.detail.message;

            if (this.message && this.message.length > 0) {
                // update the message with the label text
                if (this.label && this.label.length > 0) {
                    this.message = this.message.replace(DefaultValidationMessages.fieldPlaceholder, this.label);
                } else {
                    this.message = this.message.replace(DefaultValidationMessages.fieldPlaceholder, "This field");
                }
            }

            // toggle the label invalid class
            if (this.state === false) {
                this.$refs.label.classList.add(this.invalidLabelClass);
            } else {
                this.$refs.label.classList.remove(this.invalidLabelClass);
            }
        }
    }

    destroyed() {
        ValidatedEvent.remove(this.onValidated);
    }
}
