





































import Vue from "vue";
import { HubConnection } from "@microsoft/signalr";
import { Component } from "vue-property-decorator";
import { SignalRHubHelper } from "../ajax";

@Component({ name: "LayoutTimeTotals" })
export default class LayoutTimeTotals extends Vue {
    hubConnection: HubConnection;
    lastWeekHours: number | null = null;
    todayHours: number | null = null;
    lastBusinessDayHours: number | null = null;
    loading: boolean = false;

    beforeCreate() {
        this.hubConnection = SignalRHubHelper.createConnection("/hub/TimeEntryHub");
    }

    created() {
        this.hubConnection.start().then(() => {
            this.hubConnection.on("TimeEntryLogged", this.updateTotals);

            setInterval(this.updateTotals, 8 * 60 * 60 * 1000); // update totals every 8 hours automatically
            return this.updateTotals();
        });
    }

    async updateTotals(): Promise<void> {
        this.loading = true;

        const model = await this.hubConnection.invoke("GetTimeEntryTotals", new Date().getTimezoneOffset());

        this.todayHours = model.todayHours;
        this.lastWeekHours = model.lastWeekHours;
        this.lastBusinessDayHours = model.lastBusinessDayHours;

        this.loading = false;
    }

    destroyed() {
        this.hubConnection.stop();
    }
}
