import IDictionary from "./IDictionary";

// based on the example at https://www.dustinhorne.com/post/2016/06/09/implementing-a-dictionary-in-typescript
export default class Dictionary<T> implements IDictionary<T> {
    private _items: { [index: string]: T } = {};

    private _count: number = 0;

    public containsKey(key: string): boolean {
        return this._items.hasOwnProperty(key);
    }

    public count(): number {
        return this._count;
    }

    public add(key: string, value: T): void {
        if (!this._items.hasOwnProperty(key)) {
            this._count++;
        }

        this._items[key] = value;
    }

    public remove(key: string): T {
        const val = this._items[key];
        delete this._items[key];
        this._count--;
        return val;
    }

    public item(key: string): T {
        return this._items[key];
    }

    public keys(): string[] {
        const keySet: string[] = [];

        for (const prop in this._items) {
            if (this._items.hasOwnProperty(prop)) {
                keySet.push(prop);
            }
        }

        return keySet;
    }

    public values(): T[] {
        const values: T[] = [];

        for (const prop in this._items) {
            if (this._items.hasOwnProperty(prop)) {
                values.push(this._items[prop]);
            }
        }

        return values;
    }

    public clear(): void {
        this._items = {};
        this._count = 0;
    }
}
