import Vue from "vue";

import { Fetch } from "../../ajax";
import { ISelectListItem } from "../../models";
import { GridRequest } from "./GridRequest";
import { IDataResponse } from "./IDataResponse";
import { IProviderCtx } from "./IProviderCtx";
import { SortDirection } from "./SortDirection";

// This should only be used in the "BaseTable" component now. Existing tables should be refactored
export class VueTable<TModel = any> extends Vue {
    protected dataItems: Array<TModel> = [];
    sortColumn: string | null = null;
    sortDescending: boolean = false;
    pageNumber: number = 1;
    pageSize: number = 25;
    totalPages: number = 0;
    totalItemCount: number = 0;

    pageSizes: Array<number> = [25, 50, 100];

    get pageSizeOptions(): Array<ISelectListItem> {
        return this.pageSizes.map(s => {
            return { text: s.toString(), value: s };
        });
    }

    handleRequest(url: string, ctx: IProviderCtx, filter?: () => any): Promise<TModel[]> {
        const requestData = new GridRequest();
        requestData.sortField = ctx.sortBy || "";
        requestData.sortDirection = ctx.sortDesc ? SortDirection.Descending : SortDirection.Ascending;
        requestData.pageIndex = ctx.currentPage - 1;
        requestData.pageSize = ctx.perPage;
        requestData.searchModel = filter ? filter() : {};
        this.$emit("data-request", ctx, requestData);
        return Fetch.postJson(url, requestData).then((response: IDataResponse<TModel>) => {
            this.$emit("data-loaded", response.items);
            this.dataItems = response.items;
            this.totalPages = response.pageCount;
            this.pageNumber = response.pageNumber;
            this.totalItemCount = response.totalItemCount;
            return this.dataItems;
        });
    }
}
