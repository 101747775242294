import { RouteConfig } from "vue-router";

/**
 * Gets a RouteConfig with a dynamic component
 * @param view relative view name, without the .vue extension
 * @param path route path
 * @param title route title
 * @param breadcrumbs breadcrumbs
 * @param bodyClass the body class
 */
export default function getRouteConfig(
    view: string,
    path: string,
    title: string,
    breadcrumbs: RouteConfig[],
    role?: string
): RouteConfig {
    return {
        path: path,
        name: title.replace(" ", ""),
        meta: {
            title: title,
            breadcrumbs,
            role: role
        },
        // Note: routes are lazy loaded with dynamic async imports - see https://router.vuejs.org/guide/advanced/lazy-loading.html#lazy-loading-routes
        component: () => import(/* webpackChunkName: "view-[request]" */ `../views/${view}.vue`)
    };
}
