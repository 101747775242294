










import { Component, Prop, Watch } from "vue-property-decorator";
import { Fetch } from "../ajax";
import { ISelectListItem } from "../models";
import Autocomplete from "./Autocomplete.vue";
import BootstrapVueInput from "./BootstrapVueInput";

@Component({ name: "TaskAutocomplete" })
export default class TaskAutocomplete extends BootstrapVueInput {
    @Prop({ required: false, type: Boolean, default: false })
    anyStatus: boolean | null;

    @Prop({ required: true })
    projectId: number | null;

    @Prop({ required: false, type: Boolean, default: false })
    isDisabled: boolean | null;

    isLoading: boolean = false;

    items: ISelectListItem[] = [];

    $refs: { taskAutocomplete: Autocomplete };

    mounted() {
        if (this.projectId) {
            this.projectIdChange();
        }
    }

    get url(): string | null {
        if (this.projectId) {
            const usp = new URLSearchParams({
                anyStatus: this.anyStatus ? "true" : "false",
                projectId: this.projectId.toString()
            });

            return `/api/Task/Items?${usp.toString()}`;
        }

        return null;
    }

    fetchAndAssignItems() {
        if (this.url) {
            this.isLoading = true;
            Fetch.fetchJson(this.url, "GET").then(
                (result: ISelectListItem[]) => {
                    this.items = result;
                    this.isLoading = false;

                    result.filter((item) => item.value == this.localValue);

                    if (result.length === 0) {
                        this.$refs.taskAutocomplete.isOpen = true;
                    }

                    this.$emit("loaded", this.items);
                }
            );
        } else {
            this.items = [];
            this.isLoading = false;
            this.$emit("loaded", this.items);
        }
    }

    @Watch("projectId")
    projectIdChange() {
        this.fetchAndAssignItems();
    }
}
