import { IFileInfo } from "../models";

export default class ExcelExport {
    async getFile(
        url: string,
        method: "POST" | "GET" = "POST",
        body: any | null = null
    ): Promise<IFileInfo> {
        const headers = {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest"
        };
        const response = await fetch(url, {
            method: method,
            body: JSON.stringify(body || {}),
            headers: headers,
            credentials: "same-origin"
        });

        if (!response.ok) {
            return Promise.reject(response);
        }

        let fileName = "Export.xlsx";
        // parse the  filename from the content-disposition header
        // based on https://stackoverflow.com/a/40940790/26226
        const headerValue = response.headers.get("content-disposition");
        if (headerValue && headerValue.indexOf("attachment") !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(headerValue);
            if (matches != null && matches[1]) {
                fileName = matches[1].replace(/['"]/g, "");
            }
        }
        const fileInfo: IFileInfo = {
            fileName,
            contents: await response.blob()
        };

        return Promise.resolve(fileInfo);
    }

    showFile(fileInfo: IFileInfo) {
        // based on https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([fileInfo.contents], {
            type: fileInfo.contents.type
        });

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement("a");
        link.href = data;
        link.download = fileInfo.fileName || "Export.xlsx";
        link.click();
    }
}
