import axios from "axios";

import { NetworkErrorEventModel } from "../ajax";
import store from "../store";
import { AppMutationTypes } from "../store/mutations";

export const AXIOS_EVENT_NAME = "axios-error";

/** Configure axios to emit a "unauthorized" event on the app above when a 401 response is found */
export default function configureAxios(): void {
    // https://github.com/axios/axios#interceptors
    axios.interceptors.response.use(undefined, error => {
        if (error.response) {
            if (error.response.status === 401) {
                // When a 401 response is found, remove the current user to prompt re-authentication.
                store.commit(AppMutationTypes.setUser, null);
            }
            const errorEvent = new CustomEvent<NetworkErrorEventModel>(NetworkErrorEventModel.EVENT_NAME, {
                detail: {
                    httpStatusCode: error.response.status,
                    message: error.message || "An error occurred while processing the request."
                }
            });

            window.dispatchEvent(errorEvent);
        }

        // Axios expects a returned rejected promise with the error information:
        return Promise.reject(error);
    });
}
