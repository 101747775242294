












import Vue from "vue";
import { Component } from "vue-property-decorator";
import { NetworkErrorEventModel } from "../ajax";

@Component({ name: "AuthenticationChecker" })
export default class AuthenticationChecker extends Vue {
    showModal: boolean = false;
    modalMessage: string | null = null;
    httpStatusCode: number | null = null;

    mounted() {
        // handle network errors
        window.addEventListener(NetworkErrorEventModel.EVENT_NAME, this.eventHandler);
    }

    eventHandler(event: CustomEvent<NetworkErrorEventModel> | Event): void {
        const networkErrorEvent = event as CustomEvent<NetworkErrorEventModel>;
        if (networkErrorEvent.detail && networkErrorEvent.detail.httpStatusCode === 401) {
            this.showModal = true;
            this.modalMessage = networkErrorEvent.detail.message;
        }
    }

    okClick() {
        window.location.reload();
    }
}
