







































import Vue from "vue";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { Component, Prop, Watch } from "vue-property-decorator";
import { INotificationModel } from "../models";
import { SignalRHubHelper } from "../ajax";

@Component({ name: "NotificationItemDropdown" })
export default class NotificationItemDropdown extends Vue {
    @Prop({ default: "danger" })
    unreadVariant: string;
    @Prop({ default: "fa-exclamation" })
    unreadIcon: string;

    hubConnection: HubConnection;
    notifications: INotificationModel[] = [];
    docTitle: string;

    beforeCreate() {
        // save the initial title as a data property
        this.docTitle = document.title;
        this.hubConnection = SignalRHubHelper.createConnection("/hub/NotificationHub");
    }

    created() {
        this.hubConnection.start().then(() => {
            this.hubConnection.invoke("GetNotifications").then((items: INotificationModel[]) => {
                this.notifications = items;
            });

            this.hubConnection.on("SendNotification", (item: INotificationModel) => {
                this.notifications.unshift(item);
            });
        });
    }

    dropdownShown() {
        if (this.hubConnection && this.hubConnection.state == HubConnectionState.Connected) {
            this.hubConnection.invoke("MarkNotificationsRead").then(() => {
                setTimeout(() => {
                    this.notifications.forEach(n => {
                        n.read = true;
                    });
                }, 1500);
            });
        }
    }

    get unreadCount(): number {
        return this.notifications.filter(x => !x.read).length;
    }

    @Watch("unreadCount")
    onUnreadCountChanged(newValue: number) {
        if (newValue > 0) {
            document.title = `(${newValue}) ${this.docTitle}`;
        } else {
            document.title = this.docTitle;
        }
        this.$emit("unread-count", newValue);
    }
}
