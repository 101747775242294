












import { Component } from "vue-property-decorator";
import { AppActionTypes, AppGetterTypes } from "../store";
import BaseDropdown from "./BaseDropdown";
import VuexDropdown from "./VuexDropdown.vue";

@Component({ name: "UserDropdown", components: { "vuex-dropdown": VuexDropdown } })
export default class UserDropdown extends BaseDropdown {
    get vuexAction(): string {
        return AppActionTypes.getUserItems;
    }
    get vuexGetter(): string {
        return AppGetterTypes.userItems;
    }
}
