






























//https://vue-multiselect.js.org/
import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { ISelectListItem } from "../models";
import { AppGetterTypes } from "../store";
import BootstrapVueInput from "./BootstrapVueInput";

@Component({ inheritAttrs: false, name: "TaskStatusDropdown" })
export default class TaskStatusDropdown extends BootstrapVueInput<number | number[] | ISelectListItem[]> {
    @Prop({ default: false, type: Boolean })
    multiple: boolean;

    @Prop({ default: "All" })
    defaultText: string;

    @Prop({ default: true, type: Boolean })
    allowEmpty: boolean;

    @Getter(AppGetterTypes.taskStatusItems)
    options: ISelectListItem[];

    get localValue(): number | ISelectListItem[] {
        if (this.multiple) {
            // SelectListItem.value is a string so we need to convert it to an int to compare
            return this.options.filter(selectListItem => (this.value as Array<number>).indexOf(parseInt(selectListItem.value)) > -1);
        } else {
            return this.value as number;
        }
    }

    set localValue(newValue: number | ISelectListItem[]) {
        if (newValue instanceof Array) {
            this.$emit(
                "input",
                newValue.map(i => parseInt(i.value))
            );
        } else {
            this.$emit("input", newValue);
        }
    }
}
