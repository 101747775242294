









import { Component, Emit } from "vue-property-decorator";
import { AppActionTypes, AppGetterTypes } from "../store";
import BaseDropdown from "./BaseDropdown";
import VuexDropdown from "./VuexDropdown.vue";

@Component({
    name: "ClientDropdown",
    components: { "vuex-dropdown": VuexDropdown }
})
export default class ClientDropdown extends BaseDropdown {
    vuexAction = [AppActionTypes.getClients, AppActionTypes.getClientItems];
    vuexGetter = AppGetterTypes.clientItems;

    @Emit("change")
    change() {
        return;
    }
}
