









import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({ name: "EttDatePicker" })
export default class EttDatePicker extends Vue {
    @Prop()
    value: Date;

    @Prop({ required: false, default: "MM/dd/yyyy", type: String })
    format: string;

    created() {
        // Strip the time once created to overwrite model initialization
        this.internalValue = this.value;
    }

    get internalValue(): Date | null {
        let val: Date | null;
        if (this.value && typeof this.value === "string") {
            val = new Date(this.value);
        } else {
            val = this.value;
        }

        // remove time
        if (val) {
            return new Date(val.getFullYear(), val.getMonth(), val.getDate());
        }
        return null;
    }

    set internalValue(val: Date | null) {
        // remove time
        if (val && typeof val === "object") {
            val = new Date(val.getFullYear(), val.getMonth(), val.getDate());

            this.$emit("input", val);
        }
    }
}
