import { DirectiveOptions } from "vue";
import { DirectiveBinding } from "vue/types/options";

const classNames = {
    iconClass: "v-loading-icon",
    iconSelector: "i.v-loading-icon",
    copyClass: "copied-el",
    hiddenClass: "d-none"
};

function createLoadingElement(el: HTMLElement, binding: DirectiveBinding): HTMLElement {
    const loadingIcon = document.createElement("i");
    loadingIcon.classList.add(classNames.iconClass, "fas", "fa-spinner", "fa-spin", "ml-2");
    if (binding.modifiers.lg) {
        loadingIcon.classList.add("fa-3x");
    }

    // If "replace" modifier is set, create a copy element (same tag/CSS classes) with the loading element in it, and hide the real one.
    // Vue doesn't like it if we replace the element, so we have to copy it
    if (binding.modifiers.replace && el.parentNode) {
        const copiedEl = document.createElement(el.tagName);
        copiedEl.appendChild(loadingIcon);
        copiedEl.classList.add(classNames.copyClass);
        for (let i = 0; i < el.classList.length; i++) {
            copiedEl.classList.add(el.classList[i]);
        }

        return copiedEl;
    }

    return loadingIcon;
}

const LoadingDirective: DirectiveOptions = {
    bind(el: HTMLElement, binding: DirectiveBinding) {
        if (binding.value === true) {
            const loadingIcon = createLoadingElement(el, binding);
            el.setAttribute("disabled", "disabled");

            if (binding.modifiers.replace && el.parentNode) {
                el.classList.add(classNames.hiddenClass);
                el.parentNode.insertBefore(loadingIcon, el);
            } else {
                el.appendChild(loadingIcon);
            }
        }
    },

    update(el: HTMLElement, binding: DirectiveBinding) {
        if (binding.value != binding.oldValue) {
            if (binding.value === true) {
                // shouldn't exist so create it
                const loadingIcon = createLoadingElement(el, binding);

                el.setAttribute("disabled", "disabled");
                if (binding.modifiers.replace && el.parentNode) {
                    el.classList.add(classNames.hiddenClass);
                    el.parentNode.insertBefore(loadingIcon, el);
                } else {
                    el.appendChild(loadingIcon);
                }
            } else {
                // remove icon
                el.removeAttribute("disabled");
                if (binding.modifiers.replace && el.parentNode) {
                    const loadingIcon = el.parentNode.querySelector(`.${classNames.copyClass}`);
                    if (loadingIcon) {
                        loadingIcon.remove();
                    }
                    el.classList.remove(classNames.hiddenClass);
                } else {
                    const loadingIcon = el.querySelector(classNames.iconSelector);
                    if (loadingIcon) {
                        loadingIcon.remove();
                    }
                }
            }
        }
    },

    unbind(el: HTMLElement) {
        const loadingIcon = el.querySelector(classNames.iconSelector);
        if (loadingIcon != null) {
            loadingIcon.remove();
            el.removeAttribute("disabled");
        }
    }
};

export default LoadingDirective;
