import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

export type InputValue = number | string | string[] | null;

@Component
export default class BootstrapVueInput<TValue = InputValue> extends Vue {
    @Prop({ default: null })
    id: string | null;
    @Prop({ default: null })
    name: string | null;
    @Prop({ default: null, type: Boolean })
    state: boolean | null;

    @Prop({ default: false, type: Boolean })
    disabled: boolean;

    @Prop({ required: true })
    value: TValue;

    get localValue(): TValue {
        return this.value;
    }
    set localValue(val: TValue) {
        this.$emit("input", val);
    }
}
