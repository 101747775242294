import { NavigationGuardNext, Route } from "vue-router";

import { IUserInfo } from "../models";
import { routeNames } from "./";

/**
 * Check if the route requires role authorization and redirect to Unauthorized if the use doesn't belong to that role
 * @param to the route we're navigating to
 * @param next the next handler
 */
export default function redirectIfUnauthorized(user: IUserInfo, to: Route, next: NavigationGuardNext): void {
    if (to.meta && to.meta.role && typeof to.meta.role === "string") {
        // if the route we're transitioning to has a role requirement
        const role: string = to.meta.role;

        // if the user doesn't belong to that module, send them to the home page
        if (user && user.roles.indexOf(role) === -1) {
            next({
                name: routeNames.home
            });
        }
    }

    next();
}
