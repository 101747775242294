import { SortDirection } from "./SortDirection";

export class GridRequest {
    searchModel: any = {}; // stringified JSON
    pageIndex: number;
    pageSize: number;
    sortField: string;
    sortDirection: SortDirection = SortDirection.Ascending;

    set filter(val: any) {
        this.searchModel = val;
    }
}
