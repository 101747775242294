import InputValidator from "./InputValidator";

export default class RegexValidator extends InputValidator {
    private _expression: RegExp | null;

    constructor(
        element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement,
        message: string,
        pattern: string | RegExp | null = null
    ) {
        super(element, message);

        if (pattern && pattern instanceof RegExp) {
            this._expression = pattern;
        }

        if (pattern && typeof pattern === "string") {
            this._expression = new RegExp(pattern);
        }
    }

    public validate(): boolean {
        if (this._expression != null) {
            this.valid = this.element.value.length === 0 || this._expression.test(this.element.value);
        } else {
            this.valid = !this.element.validity.patternMismatch;
        }

        this.emit();

        return this.valid;
    }
}
