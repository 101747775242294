
















import { Component, Prop, Watch } from "vue-property-decorator";
import { Fetch } from "../ajax";
import { ISelectListItem } from "../models";
import BaseDropdown from "./BaseDropdown";

@Component({ name: "AjaxDropdown" })
export default class AjaxDropdown extends BaseDropdown {
    static cachedOptions: { [url: string]: Array<ISelectListItem> };

    @Prop({ required: true })
    url: string;
    @Prop({ default: false, type: Boolean })
    disableCache: boolean;

    @Watch("url")
    urlChanged(newValue: string, oldValue: string) {
        this.$nextTick().then(this.loadDropdown);
    }

    isCached(): boolean {
        return !!AjaxDropdown.cachedOptions && !!AjaxDropdown.cachedOptions[this.url];
    }

    get cachedValue(): Array<ISelectListItem> {
        return AjaxDropdown.cachedOptions[this.url];
    }

    set cachedValue(value: Array<ISelectListItem>) {
        if (!AjaxDropdown.cachedOptions) {
            AjaxDropdown.cachedOptions = {};
        }
        AjaxDropdown.cachedOptions[this.url] = value;
    }

    private loadDropdown(): Promise<void> {
        let promise: Promise<ISelectListItem[]>;
        if (!this.url) {
            this.options = [];
            promise = Promise.resolve(this.options);
        } else if (!this.disableCache && this.isCached()) {
            this.options = this.cachedValue;
            promise = Promise.resolve(this.options);
        } else {
            promise = Fetch.getJson(this.url).then((items: Array<ISelectListItem>) => {
                this.options = items;
                this.cachedValue = items;
                return this.options;
            });
        }

        return promise.then(options => {
            if (!options || !options.some(o => o.value == this.value)) {
                this.localValue = null;
            }
            this.$emit("loaded", options);
        });
    }

    created() {
        this.loadDropdown();
    }
}
