import { MutationTree } from "vuex";

import { ISelectListItem, ISettingsModel, IUserInfo } from "../models";
import { IClient } from "../models/clients";
import { ITimeChargeModel } from "../models/time";
import IAppState from "./IAppState";

export type SetTimeEntryMutation = (model: ITimeChargeModel) => void;
export type SetTitleMutation = (title: string) => void;
export type SetClientsMutation = (clients: IClient[]) => void;
export type SetClientItemsMutation = (items: ISelectListItem[]) => void;
export type SetUserItemsMutation = (items: ISelectListItem[]) => void;
export type SetProjectItemsMutation = (items: ISelectListItem[]) => void;

export const AppMutationTypes = {
    setSettings: "setSettings",
    setUser: "setUser",
    setTimeEntry: "setTimeEntry",
    setUpdateAvailable: "setUpdateAvailable",
    setClients: "setClients",
    setTitle: "setTitle",
    setClientItems: "setClientItems",
    setProjectItems: "setProjectItems",
    setUserItems: "setUserItems"
};

const mutations: MutationTree<IAppState> = {
    [AppMutationTypes.setSettings](state, payload: ISettingsModel) {
        state.settings = { ...payload };
    },

    [AppMutationTypes.setUser](state, payload: IUserInfo) {
        state.user = { ...payload };
    },

    [AppMutationTypes.setTimeEntry](state, payload: ITimeChargeModel) {
        state.timeEntry = { ...payload };
    },

    [AppMutationTypes.setUpdateAvailable](state, payload: boolean) {
        state.updateAvailable = payload;
    },

    [AppMutationTypes.setClients](state, payload: IClient[]) {
        state.clients = [...payload];
    },

    [AppMutationTypes.setTitle](state, payload: string) {
        state.title = payload;
    },

    [AppMutationTypes.setClientItems](state, payload: ISelectListItem[]) {
        state.clientItems = [...payload];
    },

    [AppMutationTypes.setProjectItems](
        state,
        payload: Record<number, ISelectListItem[]>
    ) {
        state.projectItems = payload;
    },

    [AppMutationTypes.setUserItems](state, payload: ISelectListItem[]) {
        state.userItems = [...payload];
    }
};

export default mutations;
