import { IRestClient, RestClient } from "@elite/restclient";

import ExcelExport from "./ExcelExport";
import FetchHelper from "./FetchHelper";
import NetworkErrorEventModel from "./NetworkErrorEventModel";
import SignalRHubHelper from "./SignalRHubHelper";

RestClient.initialize();
const restClient: IRestClient = new RestClient();

export { FetchHelper as Fetch, FetchHelper, SignalRHubHelper, restClient, ExcelExport, NetworkErrorEventModel };
