








import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { ISelectListItem } from "../models";
import { AppActionTypes, AppGetterTypes } from "../store";
import BaseDropdown from "./BaseDropdown";
import VuexDropdown from "./VuexDropdown.vue";

@Component({ name: "ProjectDropdown", components: { "vuex-dropdown": VuexDropdown } })
export default class ProjectDropdown extends BaseDropdown {
    @Prop({ required: true })
    clientId: number | null;

    @Getter(AppGetterTypes.projectItems)
    projectItems: Record<number, ISelectListItem[]>;

    vuexAction = AppActionTypes.getProjectItems;

    getOptions(): ISelectListItem[] {
        if (!this.clientId) {
            return [];
        }

        return this.projectItems[this.clientId];
    }
}
