import { GetterTree } from "vuex";

import { ISelectListItem, ISettingsModel, IUserInfo } from "../models";
import { IClient } from "../models/clients";
import { ITimeChargeModel } from "../models/time";
import IAppState from "./IAppState";

export const AppGetterTypes = {
    version: "version",
    settings: "settings",
    user: "user",
    userId: "userId",
    userItems: "userItems",
    userType: "userType",
    timeEntry: "timeEntry",
    updateAvailable: "updateAvailable",
    clients: "clients",
    title: "title",
    clientItems: "clientItems",
    projectItems: "projectItems",
    applicationItems: "applicationItems",
    taskStatusItems: "taskStatusItems",
    activeTaskStatusItems: "activeTaskStatusItems",
    externalProjectManagementItems: "externalProjectManagementItems",
    internalLinkTypeItems: "internalLinkTypeItems",
    timeEntryTypeItems: "timeEntryTypeItems",
    taskFeedbackEnvironmentItems: "taskFeedbackEnvironmentItems",
    clientProjectEnvironmentItems: "clientProjectEnvironmentItems",
    stateItems: "stateItems"
};

const getters: GetterTree<IAppState, IAppState> = {
    [AppGetterTypes.version](state): string {
        return state.version;
    },

    [AppGetterTypes.settings](state): ISettingsModel | undefined {
        return state.settings;
    },

    [AppGetterTypes.user](state): IUserInfo | undefined {
        return state.user;
    },

    [AppGetterTypes.userId](state): number | undefined {
        return state.user?.id;
    },

    [AppGetterTypes.userType](state): number | undefined {
        return state.user?.userType;
    },

    [AppGetterTypes.userItems](state): ISelectListItem[] {
        return state.userItems;
    },

    [AppGetterTypes.timeEntry](state): ITimeChargeModel {
        return state.timeEntry;
    },

    [AppGetterTypes.updateAvailable](state): boolean {
        return state.updateAvailable;
    },

    [AppGetterTypes.clients](state): IClient[] {
        return state.clients;
    },

    [AppGetterTypes.title](state): string {
        return state.title;
    },

    [AppGetterTypes.clientItems](state): ISelectListItem[] {
        return state.clientItems;
    },

    [AppGetterTypes.projectItems](state): Record<number, ISelectListItem[]> {
        return state.projectItems;
    },

    [AppGetterTypes.applicationItems]:
        (state) =>
            (projectId: number): ISelectListItem[] => {
                for (const client of state.clients) {
                    if (
                        client.applications.find((x) => x.projectId === projectId)
                    ) {
                        return client.applications
                            .filter((x) => x.projectId === projectId)
                            .map((x) => {
                                return {
                                    text: x.name,
                                    value: +x.id
                                } as ISelectListItem;
                            });
                    }
                }
                return [];
            },

    [AppGetterTypes.taskStatusItems](state): ISelectListItem[] {
        return state.settings.taskStatusItems;
    },

    [AppGetterTypes.activeTaskStatusItems](state): ISelectListItem[] {
        return state.settings.activeTaskStatusItems;
    },

    [AppGetterTypes.externalProjectManagementItems](state): ISelectListItem[] {
        return state.settings.externalProjectManagementItems;
    },

    [AppGetterTypes.internalLinkTypeItems](state): ISelectListItem[] {
        return state.settings.internalLinkTypeItems;
    },

    [AppGetterTypes.timeEntryTypeItems](state): ISelectListItem[] {
        return state.settings.timeEntryTypeItems;
    },

    [AppGetterTypes.taskFeedbackEnvironmentItems](state): ISelectListItem[] {
        return state.settings.taskFeedbackEnvironmentItems;
    },

    [AppGetterTypes.clientProjectEnvironmentItems](state): ISelectListItem[] {
        return state.settings.clientProjectEnvironmentItems;
    },

    [AppGetterTypes.stateItems](state): ISelectListItem[] {
        return state.settings.stateItems;
    }
};

export default getters;
