import DefaultValidationMessages from "./DefaultValidationMessages";
import { IValidator } from "./IValidator";
import {
    LengthValidator,
    RangeValidator,
    RegexValidator,
    RequiredValidator,
    ServerSideValidator,
    TypeValidator
} from "./validators";
import InputValidator from "./validators/InputValidator";

export default class ValidatorFactory {
    static log: boolean = false;

    // gets validators based on ths HTML 5 input validation/type - https://developer.mozilla.org/en-US/docs/Learn/HTML/Forms/Form_validation
    static getValidators(input: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): IValidator[] {
        const validators = [];

        if (input.hasAttribute("required")) {
            validators.push(
                new RequiredValidator(
                    input,
                    input.getAttribute("data-val-required") || DefaultValidationMessages.required
                )
            );
        }

        if (input.hasAttribute("email")) {
            validators.push(
                new TypeValidator(input, input.getAttribute("data-val-email") || DefaultValidationMessages.email)
            );
        }

        if (input.hasAttribute("url")) {
            validators.push(
                new TypeValidator(input, input.getAttribute("data-val-url") || DefaultValidationMessages.url)
            );
        }

        const minLength = input.getAttribute("minlength");
        const maxLength = input.getAttribute("maxlength");
        if (minLength || maxLength) {
            let lengthMessage = input.getAttribute("data-val-length");

            if (!lengthMessage) {
                if (minLength && maxLength) {
                    lengthMessage = DefaultValidationMessages.minMaxLength(minLength, maxLength);
                } else if (minLength) {
                    lengthMessage = DefaultValidationMessages.minMaxLength(minLength, null);
                } else {
                    lengthMessage = DefaultValidationMessages.minMaxLength(null, maxLength);
                }
            }

            validators.push(new LengthValidator(input, lengthMessage || ""));
        }

        const min = input.getAttribute("min");
        const max = input.getAttribute("max");
        if (min || max) {
            let lengthMessage = input.getAttribute("data-val-range");

            if (!lengthMessage) {
                if (min && max) {
                    lengthMessage = DefaultValidationMessages.range(min, max);
                } else if (min) {
                    lengthMessage = DefaultValidationMessages.range(min, null);
                } else {
                    lengthMessage = DefaultValidationMessages.range(null, max);
                }
            }

            validators.push(new RangeValidator(input, lengthMessage || ""));
        }

        if (input.hasAttribute("pattern")) {
            const value = input.getAttribute("pattern") || "";
            validators.push(
                new RegexValidator(
                    input,
                    input.getAttribute("data-val-regex") || DefaultValidationMessages.pattern(value)
                )
            );
        }

        // set any initial (server-side) errors
        const message = input.getAttribute("data-initial-message");
        if (message) {
            const customValidator = new ServerSideValidator(input, message);
            validators.push(customValidator);
        }

        // enable logging on all created validators
        if (ValidatorFactory.log === true) {
            validators.forEach((validator) => {
                const inputValidator = validator as InputValidator;
                if (inputValidator) {
                    inputValidator.log = ValidatorFactory.log;
                }
            });
        }

        return validators;
    }
}
