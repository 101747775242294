import { Component, Prop } from "vue-property-decorator";

import { ISelectListItem } from "../models";
import BootstrapVueInput, { InputValue } from "./BootstrapVueInput";

@Component
export default class BaseDropdown<TValue = InputValue> extends BootstrapVueInput<TValue> {
    /** Flag for whether or not to display a `null` valued select list item */
    @Prop({ default: true, type: Boolean })
    allowEmpty: boolean;

    /** Text for the default option when `allowEmpty` is `true` */
    @Prop({ default: "" })
    defaultText: string;

    internalOptions: ISelectListItem[] = [];

    /** Gets the options */
    get options(): ISelectListItem[] {
        return this.internalOptions;
    }
    /** Sets the options */
    set options(val: ISelectListItem[]) {
        this.internalOptions = val;
    }
}
