







import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IValidationSummary } from "../../validation";

@Component({ name: "ValSummary" })
export default class ValSummary extends Vue implements IValidationSummary {
    @Prop({ required: true })
    // server side model errors
    errors: Array<string>;
}
