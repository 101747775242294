













import { Component, Prop } from "vue-property-decorator";
import { AppGetterTypes } from "../store";
import BaseDropdown from "./BaseDropdown";
import VuexDropdown from "./VuexDropdown.vue";

@Component({ name: "ProjectManagementTypeDropdown", components: { "vuex-dropdown": VuexDropdown } })
export default class ProjectManagementTypeDropdown extends BaseDropdown {
    @Prop({ default: false, type: Boolean })
    disableCache: boolean;

    getter = AppGetterTypes.externalProjectManagementItems;
}
