









import { BModal } from "bootstrap-vue";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { ISettingsModel } from "../models";

@Component
export default class UnauthorizedModal extends Vue {
    $refs!: {
        modal: BModal;
    };

    @Getter
    settings!: ISettingsModel;

    /** Upon mounting, show the modal */
    mounted(): void {
        this.$refs.modal.show();
    }

    /** When the modal gets closed, redirect the user to a sign-in page with a redirect URI of the current path */
    modalClosed(): void {
        window.location.href =
            this.settings.signInUrl + "?redirectUri=" + encodeURIComponent(window.location.pathname);
    }
}
