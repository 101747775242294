



import { Component, Prop } from "vue-property-decorator";
import { ISelectListItem } from "../models";
import BaseDropdown from "./BaseDropdown";

@Component({ name: "ActiveDeletedDropdown" })
export default class ActiveDeletedDropdown extends BaseDropdown {
    @Prop({ default: "Inactive" })
    deletedText!: string;

    @Prop({ default: false })
    deletedValue!: any;

    @Prop({ default: "Active" })
    activeText!: string;

    @Prop({ default: true })
    activeValue!: any;

    get options(): Array<ISelectListItem> {
        return [
            { value: this.activeValue, text: this.activeText },
            { value: this.deletedValue, text: this.deletedText }
        ];
    }
}
