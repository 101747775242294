



import Vue from "vue";
import Component from "vue-class-component";
import { RouteConfig } from "vue-router";
import { IBreadcrumbModel } from "../models";

@Component
export default class Breadcrumbs extends Vue {
    breadcrumbs: IBreadcrumbModel[] = [];

    mounted() {
        this.$router.afterEach((to, from) => {
            const breadcrumbs =
                (this.$route.meta.breadcrumbs as RouteConfig[]) || [];

            // append the current route and map to IBreadcrumbModel[]
            this.breadcrumbs = [...breadcrumbs, { ...this.$route }].map(
                (route) => ({
                    text: route.meta.title as string,
                    to: { name: route.name || "", path: route.path }
                })
            );
        });
    }
}
