












import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { uniqueId } from "../lodash";
import DefaultTinyMceConfig from "./DefaultTinyMceConfig";

@Component({ name: "HtmlEditor" })
export default class HtmlEditor extends Vue {
    $refs: {
        editor: Vue;
    };

    @Prop({ default: "" })
    value: string;

    @Prop({ required: false, type: Number, default: null })
    height: number;

    @Prop({ required: false, type: String, default: null })
    name: string;

    id: string = uniqueId("html-editor-");

    mceConfig: DefaultTinyMceConfig = new DefaultTinyMceConfig();

    get internalValue(): string {
        return this.value || "";
    }

    set internalValue(val: string) {
        this.$emit("input", val);
    }

    mounted() {
        if (this.height) {
            this.mceConfig.height = this.height;
        }

        // editor doesn't automatically set the hidden textarea value on change, so we need to make sure it's set correctly for validation
        this.$watch(
            "internalValue",
            (newValue: string) => {
                if (this.$refs.editor && this.$refs.editor.$el) {
                    const element = this.$refs.editor.$el as HTMLTextAreaElement;
                    if (element) {
                        element.value = newValue;
                    }
                }
            },
            { immediate: true }
        );
    }
}
